.main-nav{
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-around;
    background-color: rgb(29, 11, 11);

}
.logo{
    width: 140px;
    height: 100px;
    transform: translateY(-15px);
}
.list-nav{
    display: flex;
    list-style: none;
    transform: translateY(-20px);

}
.list-nav li{
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    cursor: pointer;
    text-transform: capitalize;
   
}

.list-nav li:nth-child(1){
    width: 150px;
    border-bottom-left-radius: 5rem;
}
.list-nav li:nth-child(3){
    width: 150px;
    border-bottom-right-radius: 5rem;
}
.list-nav li:nth-child(1):hover{
   background-color: red;
   color: white;
}
.list-nav li:nth-child(2):hover{
    background-color: rgb(255, 196, 0);
    color: white;
}
.list-nav li:nth-child(3):hover{
    background-color: rgb(180, 0, 90);
    color: white;
}

@media screen and (max-width: 750px) {
    .list-nav{
        display: none;
    
    }
    .main-nav{
     justify-content: start;
    
    }
}