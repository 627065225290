.why-main{
    width: 100%;
    height: 60vh;
    background-color: rgb(237, 238, 240);
    display: flex;
    align-items: center;
    flex-direction: column;
    text-transform: capitalize;
    padding-top: 30px;
}
.why-content{
    width: 95%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}
.why-content img{
    width: 300px;
}
@media screen and (max-width:750px) {
    .why-main{
     width: 100%;
     height: 100vh;
     padding-top: 10px;
    }
    .why-content{
        display: flex;
        flex-direction: column;
        font-size: 18px;
    }
}