body {
  background-color: rgb(255, 255, 255);
  color: black;
  margin: 0;
  padding: 0;

  font-family: "Oswald", sans-serif;
  font-optical-sizing: auto;

}


  
