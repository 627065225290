.contact-main{
    width: 100%;
    height: 80vh;
    background-color: rgb(233, 236, 238);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
}
.contact-form{
    width: 80%;
    height: 60vh;
    background: linear-gradient(
        105deg,
        white 0%,
        white 70%,
        black 70%,
        black 100%
      );
      border-radius: 20px;
      box-shadow: 1px 1px 20px rgb(122, 122, 122);
      display: flex;
}
.container-inputs{
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.container-inputs input{
    width: 250px;
    height: 35px;
    margin-top: 20px;
    background-color: rgb(245, 245, 245);
    color: black;
    outline: none;
    border: 1px solid rgb(0, 0, 0);
    border-radius: 10px;
    padding-left: 10px;
 
}
.content-contact{
    width: 30%;
    height: 60vh;
    color: aliceblue;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
}
.container-inputs button{
    width: 100px ;
    height: 35px;
    background-color: black;
    color: aliceblue;
    margin-top: 20px;
    border-radius: 10px;
    border: none;
    text-transform: capitalize;
}
.container-inputs button:active{
    background-color: rgb(221, 15, 15);
}
@media screen and (max-width:750px) {
    .contact-form{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
    }
    .contact-form{
        background: linear-gradient(
            to top,
            white 0%,
            white 70%,
            black 70%,
            black 100%
          );
          height: 80vh;
          
    }
    .contact-main{
        height: 100vh;
    }
    .content-contact h1{
        font-size: 22px;
        width: 100px;
        margin: 0;

    }
    .content-contact{
        width: 100%;
        height: 30vh;

    }
}