.main-items{
    width: 310px;
    height:450px;
    background-color: rgb(240, 240, 240);
    margin: 40px 20px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    box-shadow: 1px 1px 15px rgb(46, 44, 44);
    cursor: pointer;
}
.main-items:hover{
    background-color: rgb(211, 204, 204);
}
.main-items img{
    width: 420px;
    height: 310px;
    margin-bottom: 0;
   
}
.title1{
    padding: 0;
    margin: 5px 0 5px 0;
    color: black;
    font-size: 25px;
}
.title2{
    padding: 0;
    margin: 0;
    color: black;
    margin: 0 10px;
    font-size: 20px;
}
.title3{
    padding: 0;
    margin: 0;
    color: black;
    font-size: 20px;
}