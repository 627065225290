.main-product {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    
}
.mainnn{
    background-color: #c7c7c7;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: rgb(0, 0, 0);
    text-transform: capitalize;
}