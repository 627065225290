.main-main{
    display: flex;
    width: 100%;
    height: 88vh;
    text-transform: capitalize;
}
.image-slider{
    filter: blur(5px);
    z-index: -10;
}
.content-main{
    position: absolute;
    display: flex;
    color: aliceblue;
    align-items: center;
    right: 110px;
    bottom: 200px;
}
.oil-img{
    width: 800px;

}
.content-main h1{
    text-align: center;
}
.hh1{
    font-size: 60px;
    margin: 0;
}

.content-main span{
    margin: 0;
    color: rgb(211, 5, 5);
}
@media screen and (max-width:750px) {
    .content-main{
        position: absolute;
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .oil-img{
        width: 250px;
    }
    .hh1{
        font-size: 40px;
        margin: 0;
    }
}