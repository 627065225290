.main-footer{
    width: 100%;
    height: 70vh;
    background-color: #00224D;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
}
.container-footer{
    width: 90%;
    height: 60vh;
    background-color: rgb(241, 241, 241);
    border-radius: 10px;
    display: flex;

}
.left-footer{
    width: 50%;
    height: 50vh;
    padding-left: 20px;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.right-footer{
    width: 50%;
    height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    border-left: 3px solid #00224D;
}
.logo-footer{
    width: 200px;
}
.list-footer{
    list-style: none;
}
.list-footer li{
    height: 32px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    cursor: pointer;
}
.list-footer li:hover{
    background-color: red;
    color: aliceblue;
}
.list-footer hr{
    width: 200px;
}

@media screen and (max-width:750px) {
    .container-footer{
        display: flex;
        flex-direction: column;
        height: 90%;
        align-items: center;
    }
    .main-footer{
        height: 100vh;
        align-items: center;
        justify-content: center;
    }
    .right-footer{
        border: none;
    }
}